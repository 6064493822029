<template>
  <div class="contanier">
    <div class="row">
      <products-Table class="flex md12 sm12 xs12"/>
    </div>
  </div>
</template>

<script>
import productsTable from './StaticRoads/StaticRoadsTable.vue'

export default {
  name: 'StaticRoads',
  components: {
    productsTable,
  },
  data () {
    return {

    }
  },
}

</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
    }
  }

</style>
